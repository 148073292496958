<template>
	<div class="trip">
		<div v-if="!isNotShowHeader" class="trip__title-wrapper trip-title">
			<div class="trip-title__left">
				<h2 class="section-title">Мои поездки</h2>
			</div>
			<div class="trip-title__right">
				<tk-link
					kind="title-btn"
					class="trip-title__toggle trip-title__toggle--inprogress"
					to="/profile/trips/upcoming"
				>
					Предстоящие
					<!-- <span class="trip-title__toggle--quantity">(3)</span> -->
				</tk-link>
				<tk-link
					kind="title-btn"
					class="trip-title__toggle trip-title__toggle--completed"
					to="/profile/trips/completed"
				>
					Завершенные
					<!-- <span class="trip-title__toggle--quantity">(2)</span> -->
				</tk-link>
			</div>
		</div>

		<router-view />
	</div>
</template>

<script>
export default {
	name: 'Trips',
	computed: {
		routesNameToShowHeader() {
			return ['profile.trips.booking-cancel', 'profile.trips.booking-edit']
		},
		isNotShowHeader() {
			return this.routesNameToShowHeader.find((itm) => itm === this.$route.name)
		}
	}
}
</script>

<style lang="sass" scoped>
.profile-back-btn
  visibility: hidden
</style>

<style lang="sass">
.trip
  position: relative

  max-width: 970px

  .section-title
    margin-top: 5px

  &__title-wrapper
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin-bottom: 30px

  .trip-title
    &__left
      display: flex
      flex-direction: column
      width: 40%

    &__right
      width: 45%
      max-width: 370px
      display: flex
      justify-content: space-between

    &__toggle
      position: relative

      max-width: 175px

      &--inprogress
        margin-right: 20px

      &--quantity
        content: ''
        position: absolute
        right: 5px
        top: 5px

        width: 10px
        height: 10px

        border-radius: 50%
        background-color: $red

// 1080
@media screen and (max-width: 1440px)
  .trip
    width: 790px
  
// 996
@media screen and (max-width: 1180px)
  .trip
    width: 720px

    .trip-title__right
      width: 45%

// 900
@media screen and (max-width: 1024px)
  .trip
    width: 650px

    .trip-title__right
      width: 50%

// 768
@media screen and (max-width: 912px)
  .trip
    width: 535px

    .section-title
      margin-bottom: 15px

    .trip-title__right
      width: 60%

// 540
@media screen and (max-width: 800px)
  .trip
    width: 100%

    .trip-title
      flex-direction: column

      &__left
        width: 100%

      &__right
        width: 100%
        justify-content: space-around

      &__toggle
        &--inprogress
          margin-right: 15px
</style>